// @import "../vendor/bootstrap/less/bootstrap";
// @import "../vendor/font-awesome/less/font-awesome";
// @import "../vendor/bootstrap-select/less/bootstrap-select";

@color-base: #447EB9; //#00619F;
@color-base-light: #FFF;
@color-base-dark: #333; //darken( @color-base, 30% ); //#364250;
@color-compliment: #FFF;


@color: #447EB9;
@color-mid: #004A8B;
@color-dark: #172B40;

@main-blue: #197CFF; //#447EB9;
@dark-blue: #364250;
@light-blue: #f7f9fb;

// Small screen / tablet
@screen-sm-min: 768px;

// Medium screen / desktop
@screen-md-min: 992px;

// Large screen / wide desktop
@screen-lg-min: 1200px;

ul, li {
    list-style: none;
    margin: 0; padding: 0;
}

* {
    outline: none;
}

html {
    height: 100%;
}

body {
    background: #fff;
    font-family: "Open Sans", "Roboto", Tahoma, sans-serif;
    font-size: 14px;
    color: @color-base-dark;
    line-height: 1.7;
    letter-spacing: normal;

    @media (min-width: @screen-sm-min) {
        font-size: 13px;
    }

    @media (min-width: @screen-md-min) {
        font-size: 13px;
    }

}

h1, h2, h3, h4, h5, h6 {
    margin: 0;
    color: @color-base-dark;
    font-weight: bold;
    &.heading {
        margin-bottom: 30px;
        color: @dark-blue;
        text-align: center;
        font-weight: bold;
        &:after {
            display: block;
            width: 80px;
            margin: 15px auto 0;
            border-bottom: 3px dotted #d8e0e9;
            content: "";
        }
    }
    span {
        color: @color-base;
    }
}

h1 {
    font-size: 2.6em;
}

h2 {
    font-size: 2.2em;
}

h3 {
    font-size: 1.9em;
}

h4 {
    font-size: 1.4em;
}

h5 {
    font-size: 1.2em;
}

h6 {
    font-size: 1em;
}

p {
    margin: 0;
}

a:hover, a:focus {
    outline: none;
    text-decoration: none;
}

.container {
    width: 100%;
    max-width: 1200px;
}

// -------------

.example {
    /* rules for mobile vertical (< 480) */

    @media (min-width: @screen-sm-min) {
        /* rules for tablet (768 > 992) */
    }
    @media (min-width: @screen-md-min) {
        /* rules for desktop (992 > 1200) */
    }
    @media (min-width: @screen-lg-min) {
        /* rules for large (> 1200) */
    }
}

.example {
    @media ( min-width: 768px ) {

    }
}

.separator {
    padding-bottom: 30px;
    margin-bottom: 30px;
    border-bottom: 3px dotted #d8e0e9;
}

// -------------

.margin-bottom {
    &-none {
        margin-bottom: 0;
    }
    &-10 {
        margin-bottom: 10px;
    }
    &-20 {
        margin-bottom: 20px;
    }
    &-30 {
        margin-bottom: 30px;
    }
}

// -------------

.btn-primary {
    padding: 10px;
    background: @color-base;
    color: #fff;
    &:hover {
        background: lighten( @color-base, 5% );
    }
}

// -------------

input[type=text], input[type=email], input[type=tel], textarea {
    -webkit-appearance: none;
}

.form-group {
    padding: 0;
    margin: 0 0 15px;
}

.form-error {
    label {
        color: #C5635C;
    }
}


.form-group.has-error {
    & .input-group > .input-group-addon {
        border-right: 0;
    }
}

.input-row {
    //margin-bottom: 10px;
}

label {
    display: block;
    color: @color-base-dark;
    text-transform: capitalize;
    span.label-desc {
        display: inline-block;
        //float: right;
        font-weight: 400;
        text-transform: capitalize;
        .has-error & {
            color: #C5635C;
        }
    }
}

.input-error {
    display: none;
    margin-top: 5px;
    .has-error & {
        color: #C5635C;
    }
}

.input-group > .form-control {

}

.input-group-addon {
    background: @color-base;
    //border: 1px solid @color-base;
    color: #fff;
    //vertical-align: top;
    //padding-top: 8px;
}

.form-control {
    font-size: inherit;
    &:focus {
        border: 1px solid #CCC;
        box-shadow: none;
    }
    &:not(.selectpicker) {
        //border: 1px solid @color-base;
        box-shadow: none;
    }
    &:not(.selectpicker, textarea) {
        line-height: 2em;
    }
    &:focus {
        background: @color-base-light;
        //border: 1px solid @color-base;
        box-shadow: none;
        font-size: inherit;
    }
    .has-error & {
        //border: 1px solid #C5635C;
        box-shadow: none;
        &:focus {
            //border: 1px solid #C5635C;
            box-shadow: none;
        }
        &::-moz-placeholder {
            //color: #C5635C;
            opacity: 1;
        }
        &:-ms-input-placeholder {
            //color: #C5635C;
        }
        &::-webkit-input-placeholder {
            //color: #C5635C;
        }
    }
    &[type=text], &[type=email] {
        height: 40px;
    }
}

.selectpicker {
    width: 100%;
    border: 1px solid #fff;
    background: #fff;
    color: @dark-blue;
    & .filter-option {
        overflow: hidden;
    }
}

.input-group-btn {
    font-size: inherit;
}

.input-group-btn:last-child > .btn {
    margin-left: 0;
}

.btn-group.open .dropdown-toggle {
    box-shadow: none;
}

.open > .dropdown-toggle.btn-default {
    background-color: #FFF;
    border-color: #CCC;
}

.input-group-btn:last-child > .btn {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}


.btn-default {
    border: 1px solid #fff;
    background-color: #fff;
    color: @dark-blue;
}

span.twitter-typeahead {
    width: 100%;
}
.input-group span.twitter-typeahead {
    display: block !important;
}
.input-group span.twitter-typeahead .tt-menu {
    top: 32px !important;
}
.input-group.input-group-lg span.twitter-typeahead .tt-menu {
    top: 44px !important;
}
.input-group.input-group-sm span.twitter-typeahead .tt-menu {
    top: 28px !important;
}

.tt-query {
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.tt-hint {
    color: #999
}

.tt-menu {
    width: 300px;
    margin-top: 4px;
    padding: 4px 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, 0.2);
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    -webkit-box-shadow: 0 5px 10px rgba(0,0,0,.2);
    -moz-box-shadow: 0 5px 10px rgba(0,0,0,.2);
    box-shadow: 0 5px 10px rgba(0,0,0,.2);
}

.tt-suggestion {
    padding: 3px 20px;
    line-height: 24px;
}

.tt-suggestion.tt-cursor {
    color: #fff;
    background-color: #0097cf;
}

.tt-suggestion:hover {
    cursor: pointer;
    background-color: @color-base;
    color: #fff;
}

.tt-suggestion p {
    margin: 0;
}

.dropdown-menu {
    font-size: inherit;
}


@media screen and (-webkit-min-device-pixel-ratio: 0) {
    select:focus, textarea:focus, input:focus {
        font-size: 16px;
    }
}

.cta-button {
    display: inline-block;
    padding: 10px 30px;
    border-radius: 4px;
    color: #ffffff;
    font-weight: 700;
    text-shadow: 0 -1px 0 rgba(0,0,0,0.4);
    background: #EC971F linear-gradient(to bottom, rgba(255, 255, 255, 0.1) 0, rgba(255, 255, 255, 0) 100%);
    box-shadow: 0 -1px darken( #EC971F, 10% ) inset, 0 1px lighten( #EC971F, 10% ) inset;
    border: 1px solid darken( #EC971F, 10% );
    border-top: 1px solid lighten( #EC971F, 5% );
    text-transform: uppercase;
}

.cta-button:hover {
    background-image: linear-gradient(to bottom, rgba(255,255,255,0.3) 0%,rgba(255,255,255,0.1) 100%);
    color: #fff;
}

.cta-button:active{
    position: relative;
    top: 1px;
    color: #fff;
}

.cta-button:focus {
    color: #fff;
}

.cta-button-blue {
    padding: 10px 30px;
    border-radius: 4px;
    color: #ffffff;
    font-weight: 700;
    text-shadow: 0 -1px 0 rgba(0,0,0,0.4);
    background: @color-base linear-gradient(to bottom, rgba(255, 255, 255, 0.1) 0, rgba(255, 255, 255, 0) 100%);
    box-shadow: 0 -1px darken( @color-base, 10% ) inset, 0 1px lighten( @color-base, 10% ) inset;
    border: 1px solid darken( @color-base, 10% );
    border-top: 1px solid lighten( @color-base, 5% );
}

.cta-button-white
{
    color: @color-base-dark;
    text-shadow: none;
    background: #FFF;
    box-shadow: none;
    border: 1px solid rgba(51, 51, 51, 0.3);
    border-top: 1px solid rgba(51, 51, 51, 0.3);
}

.cta-button-white:active, .cta-button-white:focus
{
    color: @color-base-dark;
}

.cta-button-white:hover
{
    color: @color-base-dark;
    background: #F7F7F7;
}


// -------------

.modal {
    webkit-overflow-scrolling: none;
}

.modal-open {
    position: fixed;
    width: 100%;
    overflow: hidden;
}

.modal-backdrop {
    z-index: 0;
}

.modal-dialog {
    width: auto;
    max-width: 800px;
}

.modal-content {
    //padding: 0 10px;
}

.modal-body {
    .close {
        font-size: 3em;
        line-height: inherit;
    }
    h3 {

    }
    h4, h5 {
        margin-top: 20px;
        //margin-bottom: 10px;
        color: @color-base;
        &:after {
            display: block;
            margin-bottom: 20px;
            padding-bottom: 5px;
            border-bottom: 1px solid rgba( 0, 0, 0, 0.1 );
            content: "";
        }
    }
    p.note {
        font-weight: 300;
        text-align: center;
    }
    .row {
        margin-left: -5px;
        margin-right: -5px;
    }
    div[class*='col'] {
        padding: 0 5px;
    }
    & .modal-section {
        &:last-of-type {
            padding-bottom: 20px;
        }
        &:not(:last-of-type) {
            padding-bottom: 20px;
            margin-bottom: 20px;
            border-bottom: 3px dotted #d8e0e9;
        }
    }
    .modal-sep {
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 3px dotted #d8e0e9;
    }
    .modal-btn {
        display: block;
        margin-top: 20px;
        margin-bottom: 10px;
        text-align: center;
    }
    .selectpicker {
        height: 40px;
        border: 1px solid @color-base;
        box-shadow: none;
        //line-height: 2em;
        background: #fff;
    }
    .form-control[type=text], .form-control[type=email], .form-control[type=tel] {
        height: 40px;
    }
    .input-group-addon i {
        font-size: 1.2em;
    }
    .input-group-btn button, .input-group-btn .dropdown-toggle.btn-default {
        height: 40px;
        background-color: @color-base;
        border: 1px solid @color-base;
        color: rgb(255, 255, 255);

    }
    .dropdown-menu > .active > a, .dropdown-menu > .active > a:focus, .dropdown-menu > .active > a:hover {
        background-color: @color-base;
    }
    .btn-primary {
        max-width: 400px;
        font-weight: bold;
        text-transform: uppercase;
    }
}

.has-error {
    .input-group-btn button, .input-group-btn .dropdown-toggle.btn-default {
        //background-color: #C5635C;
        //border: 1px solid #C5635C;
    }
}

.modal-body
.modal-body
.modal-body .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
    width: 100%;
}




.modal-body {
    & .quote-form-section {
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 3px dotted #d8e0e9;
        &:last-of-type {
            background: black;
            margin-bottom: 20px;
        }
    }
}

.modal-body .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn){
    width: 100%;
}

.modal-header h3,
.modal-header h4,
.modal-header h5,
.modal-header h6 {
    text-align: center;
}

.modal-body .form-horizontal,
.modal-body .form-group {
    margin-left: 0px;
    margin-right: 0px;
}
.modal-body .icon-password {
    font-size: 5.625em;
    line-height: 0px;
    margin-top: 35px;
    display: block;
}
.modal-body .icon-repeat {
    font-size: 3.125em;
    line-height: 0px;
    display: block;
    margin-top: 20px;
}

/*.modal-body .form-control{
    height: auto;
}*/

/*
.modal-body button {
    font-size: 1.125em;
    font-weight: 600;
    padding: 10px;
}*/

#js--modal-dynamic {
    .modal-body {
        max-height: 300px;
        overflow-y: scroll;
    }
}

// ------------------------------------------------

#header
{
    padding-top: 100px;

    @media ( min-width: @screen-sm-min )
    {
        padding-top: 75px;
    }
}

#header-bar-final
{
    z-index: 2000;
    position: fixed;
    top: 0;
    width: 100%;
    height: 100px;
    background: rgba( 255, 255, 255, 1 );
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.3);

    @media ( min-width: @screen-sm-min )
    {
        height: 75px;
    }

    .logo
    {
        font-family: Roboto, Helvetica, Arial, sans-serif;
        font-size: 2em;
        font-weight: 700;
        text-align: center;
        text-transform: uppercase;

        @media ( max-width: 320px )
        {
            font-size: 1.7em;
        }

        @media ( min-width: @screen-sm-min )
        {
            float: left;
            text-align: left;
            //line-height: 75px;
            line-height: 1.2;
            padding: 12px 0;
        }

        a, a:hover
        {
            color: @color-base-dark;
        }

        span {
            display: block;
            font-size: 0.51em;
            font-weight: 400;
            color: @color-base;
            letter-spacing: 0.3px;
        }
    }

    .navigation
    {
        text-align: center;

        @media ( min-width: @screen-sm-min )
        {
            float: right;
            text-align: left;
        }

        .cta-button
        {
            @media ( max-width: 320px )
            {
                padding: 10px 15px;
            }

            @media ( min-width: @screen-sm-min )
            {
                margin: 15px 0;
            }
        }

        ul {
            font-size: 0;
            li {
                display: inline-block;
                font-size: 13px;
                font-weight: 700;
                text-transform: uppercase;

                &:last-of-type
                {
                    margin-left: 15px;
                }

                span {
                    //color: @color-base;
                }
                /*a.cta-button {
                    padding: 10px 30px;
                    border-radius: 4px;
                    background: @color-base;
                    color: #ffffff;
                    text-shadow: 0 -1px 0 rgba(0,0,0,0.4);
                    background: #EC971F linear-gradient(to bottom, rgba(255, 255, 255, 0.1) 0, rgba(255, 255, 255, 0) 100%);
                    box-shadow: 0 -1px darken( #EC971F, 10% ) inset, 0 1px lighten( #EC971F, 10% ) inset;
                    border: 1px solid darken( #EC971F, 10% );
                    border-top: 1px solid lighten( #EC971F, 5% );
                }
                a.cta-button:hover {
                    background: darken( @color-base, 10% );
                }
                a.cta-highlight {
                    background: #EC971F linear-gradient(to bottom, rgba(255, 255, 255, 0.2) 0, rgba(255, 255, 255, 0) 100%);
                }*/
            }
            li.cta
            {
                @media ( min-width: @screen-sm-min )
                {
                    display: none;
                }
            }
            li.cta-visible {
                display: inline-block;
            }
        }
    }

}

#header-bar-container {
    min-height: 90px;
    #header-bar {
        z-index: 1001;
        position: relative;
        width: 100%;
        min-height: 90px;
        background: rgba(255, 255, 255, 0.94);
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
        box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 0px 0px, rgba(0, 0, 0, 0.1) 0px 1px 4px;
        &.fixed {
            @media ( min-width: @screen-sm-min ) {
                position: fixed !important;
                top: 0;
                left: 0;
            }
        }
        .logo {
            display: block;
            padding-top: 18px;
            font-family: "Roboto", Tahoma, sans-serif;
            text-align: center;
            line-height: 1;
            span {
                display: block;
            }
            span:nth-of-type(1) {
                //margin-bottom: 10px;
                font-size: 2.2em; //2.5em;
                //font-weight: bold;
                color: @color-base-dark;
                text-transform: uppercase;
                &:after {
                    display: block;
                    width: 350px;
                    margin: 4px auto;
                    border-bottom: 1px solid rgba( 0, 0, 0, 0.1 );
                    content: "";
                }
                @media (max-width: @screen-sm-min) {
	                font-size: 1.8em;
	            }
            }
            span:nth-of-type(2) {
                font-size: 1.3em;
                font-weight: normal;
                color: @color-base;
                @media (max-width: @screen-sm-min) {
	                font-size: 1.1em;
	            }
            }
        }
        .button-call, .button-quote {
            //margin-top: 25px;
            width: 150px;
            //font-size: 1.1em;
            font-weight: bold;
            text-transform: uppercase;
            text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.75);
            i {
                display: inline;
            }
            @media (max-width: @screen-sm-min) {
	            width: auto;
	        }
        }
        /* .button-call {
            @media (min-width: @screen-sm-min) {
                float: left;
            }
        }
        .button-quote {
            @media (min-width: @screen-sm-min) {
                float: right;
            }
        } */
        
        .headerButtons{
	        position: relative;
	        @media (min-width: @screen-sm-min) {
		        .button-call{
			        position: absolute;
			        left: 0;
			        top: -53px;
		        }
		        .button-quote{
			        position: absolute;
			        right: 0;
			        top: -53px;
		        }
		    }
		    @media (max-width: @screen-sm-min) {
			    padding: 10px 0;
			    text-align: center;
			}
	    }
    }
}

// ------------------------------------------------

#header-feature {
    width: 100%;
    padding: 20px 0;
    background: #222;
    background-size: cover;

    @media ( min-width: @screen-sm-min ) {
        background: transparent;
        height: 500px;
        padding: 0;
    }

    &.header-feature-vcentre {

        #header-feature-gradient {
            display: table;
        }

        .container {
            display: table-cell;
            height: 100%;
            vertical-align: middle;
            text-align: center;
        }

    }

    #header-feature-gradient {
        width: 100%;
        height: 100%;
        background-image: linear-gradient( to bottom, rgba( 21, 39, 57, 0.6 ), rgba( 21, 39, 57, 0.3 ) ); //linear-gradient( to bottom, rgba( 21, 39, 57, 0.6 ), rgba( 21, 39, 57, 0 ) );
    }

    #header-feature-text {
        display: inline-block;
        margin-bottom: 25px;
        width: auto;
        //background: none repeat scroll 0% 0% rgba(0, 0, 0, 0.5);
        //box-shadow: 0px 0px 40px 40px rgba(0, 0, 0, 0.5);
        text-align: center;
        & h1 {
            margin-bottom: 20px;
            color: #fff;
            font-size: 2.2em;
            font-weight: 900;
            letter-spacing: 1px;
            //line-height: 1.2em;
            text-transform: uppercase;
            text-shadow: 0 1px 3px rgba( 0, 0, 0, 0.7 );
            @media ( min-width: @screen-sm-min ) {
                font-size: 3.2em;
            }
            /*&:after {
                display: block;
                width: 80px;
                margin: 20px auto;
                border-bottom: 2px solid rgba( 255, 255, 255, 1 );
                content: "";
            }*/

        }
        & span.sub {
            display: inline;
            background: rgba( 0, 0, 0, 0.25 );
            box-shadow: 10px 0 10px rgba( 0, 0, 0, 0.25 ), -10px 0 10px rgba( 0, 0, 0, 0.25 );
            box-decoration-break: clone;
            color: #fff;
            font-size: 1.6em;
            font-weight: 300;
            line-height: 1.5em;
            text-shadow: 0 1px 10px rgba( 0, 0, 0, 1 );
            & span.bold {
                font-weight: bold;
            }
            & a{
                color: #fff;
                text-decoration: underline;
            }
        }
    }
    #header-feature-cta {
        margin: 0 auto;
        padding: 5px;
        width: 300px;
        background: rgba(255,255,255,0.2);
        border: 1px solid rgba(255,255,255,0.1);
        border-radius: 4px;
        box-shadow: rgba(0,0,0,0.3) 0px 1px 3px;

        @media ( min-width: @screen-sm-min ) {
            width: 400px;
        }

        span.cta-heading {
            display: block;
            margin-bottom: 8px;
            font-size: 1.6em;
            font-weight: bold;
            color: #FFF;
            text-align: center;
            text-shadow: 2px 1px 3px rgba( 0, 0, 0, 0.7 );
        }
        & .selectpicker {
            width: 100%;
            //height: 50px;
        }
        .js-car-make .filter-option:before,
        .js-car-model .filter-option:before,
        .js-car-badge .filter-option:before {
            display: inline-block;
            width: 50px;
            height: 100%;
            margin-right: 10px;
            border-right: 1px solid rgba( 0, 0, 0, 0.5 );
            font-weight: bold;
            content: "Make";
        }
        .js-car-model .filter-option:before {
            content: "Model";
        }
        .js-car-badge .filter-option:before {
            content: "Series";
        }
        & .form-group {
            margin-bottom: 8px;
        }
        & .form-group-btns {
            text-align: center;
        }
        & .btn-cta {
            width: 100%; //150px;
            height: 50px;
            color: #fff;
            font-weight: 600;
            text-shadow: 0px -1px 0px rgba(0,0,0,0.5);
            border: 1px solid rgba(255,255,255,0.5);
            background-image: linear-gradient(to bottom, rgba(255,255,255,0.2) 0%,rgba(255,255,255,0) 100%);
            box-shadow: rgba(0,0,0,0.8) 0px 0px 16px;
            //letter-spacing: 0.05em;
            text-transform: uppercase;
            &:hover{
                background-image: linear-gradient(to bottom, rgba(255,255,255,0.3) 0%,rgba(255,255,255,0.1) 100%);
            }
            &:active{
                position: relative;
                top: 1px;
            }
        }
        & .btn-get-quote {
            //margin-left: 10px;
            background-color: #EC971F;
        }
        & .btn-learn-more {
            background-color: #447eb9;
        }
    }


    & #header-heading {
        text-align: center;
        & h1 {
            font-weight: 800;
            text-shadow: rgba(0, 0, 0, 0.5) 0px 1px 2px, rgba(0, 0, 0, 1) 0px 1px 80px, rgba(0, 0, 0, 1) 0px 1px 60px;
        }
        & h1 + span {
            display: block;
            color: #fff;
            font-size: 1.5em;
            font-weight: 500;
        }
    }
    & .header-cta-form {

    }
}

// ------------------------------------------------

#header-feature-final {

    position: relative;
    height: 100%;
    padding-bottom: 20px;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.3);

    @media (min-width: @screen-sm-min) {
        min-height: 650px;
        background: url('../img/header/nobg/background.jpg') no-repeat center bottom;
        background-size: cover;
    }

    //background: url('../img/header/nobg/background.jpg') no-repeat center bottom;
    //background: url('../img/header/nobg/background.jpg') no-repeat scroll center bottom / 100% auto #FFF;
    line-height: 1.5;

    .gradient {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 1;
        background: transparent linear-gradient(to bottom, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.6)) repeat scroll 0% 0%;
    }

    .container {
        height: 100%;
        padding-top: 20px;
        .row {
            height: 100%;
        }
    }

    .important-message
    {
        border: 1px solid darken( @color-base, 10% );
        border-radius: 4px;
        padding: 8px;
        margin-bottom: 10px;
        background: @color-base;
        color: #FFF;

        strong
        {
            font-size: 1.1em;
        }
    }

    h1 {
        margin-bottom: 10px;
        //font-family: "Roboto", Tahoma, sans-serif;
        font-size: 2em;
        font-weight: 700;
        text-transform: uppercase;

        span {
            color: @color-base-dark;
        }

        @media (max-width: @screen-sm-min) {
            line-height: 1.2;
        }

        @media (min-width: @screen-sm-min) {
            margin-top: 10px;
        }
    }

    #header-feature-left,
    #header-feature-right {
        position: relative;
        height: 100%;

        @media (min-width: @screen-sm-min) {
            height: 630px;
        }
    }

    #header-feature-benefits {
        //padding-top: 5px;
        margin-bottom: 20px;
        margin-left: 10px;
        li {
            margin-bottom: 15px;
            i {
                margin-right: 5px;
                color: @color-base;
                font-size: 1.1em;

            }
            span {
                font-size: 1.1em;
                font-weight: 700;
            }
            p {

            }
            /*i {
                float: left;
                margin-top: 15px;
                margin-right: 10px;
                color: @color-base;
                font-size: 1.5em;
            }*/
            strong {
                font-size: 1.2em;
            }
        }
    }

    .learn-more
    {
        text-align: center;

        @media ( min-width: @screen-sm-min )
        {
            text-align: center;
        }

    }

    #header-feature-car
    {
        position: relative;
        margin-top: 20px;
        margin-bottom: 10px;

        @media ( min-width: @screen-sm-min )
        {
            position: absolute;
            bottom: 0;
            left: 0;
            margin-bottom: 0;
        }


        img {
            width: 100%;
            max-width: 100%;
        }
    }

    #header-feature-form {
        background: rgba(255,255,255,0.7);
        border-radius: 4px;
        padding: 10px;
        border: 1px solid fade( @color-base-dark, 30% );// rgba(255,255,255,0.1);
        //box-shadow: rgba(0,0,0,0.3) 0px 1px 3px;
    }

    .request-quote-form {
        h1 {
            margin-top: 0;
        }
    }

}


.request-quote-form {
    p.note {
        //font-weight: 300;
        text-align: center;
    }
    .row {
        margin-left: -5px;
        margin-right: -5px;

        /*@media (min-width: @screen-sm-min) {
            div:last-of-type {
                padding-left: 0;
            }
        }*/

    }
    div[class*='col'] {
        padding: 0 5px;
    }

    .form-group {
        margin-bottom: 12px;
    }

    label {
        margin-bottom: 6px;
        line-height: 1;
    }

    p.separator {
        height: 1px;
        border-bottom: 3px dotted fade( @color-base, 30% );
        margin-bottom: 20px;
        padding-bottom: 10px;
    }

    p.heading {
        padding-bottom: 3px;
        margin-bottom: 12px;
        //border-bottom: 1px dotted @color-base; //lighten( @color-base, 30% );
        border-bottom: 1px solid fade( @color-base, 30% );
        color: @color-base;
        font-weight: 700;
        text-transform: capitalize;
        line-height: 1;
        font-size: 1.1em;

        @media (max-width: @screen-sm-min) {
            line-height: 1.5;
        }

        span
        {
            color: @color-base-dark;
            font-size: 0.7em;
            font-weight: 400;
            text-transform: none;
        }
    }

    select {
        padding-left: 8px;
    }
    .selectpicker {
        height: 35px;
        border: 1px solid #ccc;
        box-shadow: none;
        //line-height: 2em;
        background: #fff;
    }
    .dropdown-menu > .selectpicker {
        height: 100%;
    }
    .input-group-addon {
        padding-right: 0;
        border: 1px solid fade( @color-base-dark, 30% );
        border-right: 0;
    }
    .form-control, .selectpicker {
        border: 1px solid fade( @color-base-dark, 30% );
        border-left: 0;
    }
    .form-control[type=text], .form-control[type=email], .form-control[type=tel] {
        height: 35px;
    }
    .input-group-addon {
        background: #fff;
        color: @color-base;
    }
    .input-group-addon i {
        font-size: 1.2em;
    }
    /*.input-group-btn button, .input-group-btn .dropdown-toggle.btn-default {
        height: 40px;
        background-color: @color-base;
        border: 1px solid @color-base;
        color: rgb(255, 255, 255);

    }*/
    .dropdown-menu > .active > a, .dropdown-menu > .active > a:focus, .dropdown-menu > .active > a:hover {
        background-color: @color-base;
    }

    .open > .dropdown-toggle.btn-default {
        border-color: fade( @color-base-dark, 30% );
    }

    .cta-button {
        margin-top: 5px;
        margin-bottom: 10px;
        width: 100%;
        height: 50px;
        font-size: 1.2em;
    }

    .btn-cta {
        margin-top: 5px;
        margin-bottom: 10px;
        width: 100%;
        height: 50px;
        color: #fff;
        font-size: 1.2em;
        font-weight: 700;
        text-shadow: 0 -1px 0 rgba(0,0,0,0.4);
        //border: 1px solid rgba(255,255,255,0.5);
        background: #EC971F linear-gradient(to bottom, rgba(255, 255, 255, 0.1) 0, rgba(255, 255, 255, 0) 100%);
        box-shadow: 0 -1px darken( #EC971F, 10% ) inset, 0 1px lighten( #EC971F, 10% ) inset;
        border: 1px solid darken( #EC971F, 10% );
        border-top: 1px solid lighten( #EC971F, 5% );

        //background-image: linear-gradient(to bottom, rgba(255,255,255,0.2) 0%,rgba(255,255,255,0) 100%);
        text-transform: uppercase;



        &:hover{
            background-image: linear-gradient(to bottom, rgba(255,255,255,0.3) 0%,rgba(255,255,255,0.1) 100%);
        }
        &:active{
            position: relative;
            top: 1px;
        }
    }
}


// ------------------------------------------------

#header-feature-new {
    background: url('../img/header/nobg/background.jpg') no-repeat scroll center bottom / 100% auto #405570;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 0px 0px, rgba(0, 0, 0, 0.1) 0px 1px 4px;
    .container {

    }
    .floatLeft{
        @media (min-width: 710px){
            float: left;
            width: 50%;
            position: relative;
            left: 10px;
            pointer-events: none;
        }
    }
    .floatRight{
        @media (min-width: 710px) {
            float: right;
            width: 50%;
        }
    }
    h1 {
        margin-top: 50px;
        margin-bottom: 10px;
        font-size: 3.5em;
        font-weight: 900;
        color: @color-base-dark; //#fff;
        text-align: center;
        //line-height: 1.2em;
        text-transform: uppercase;
        //text-shadow: 2px 1px 3px rgba( 0, 0, 0, 0.7 );
    }
    h1 + span {
        display: block;
        color: @color-base-dark;
        font-size: 1.6em;
        //font-weight: 300;
        text-align: center;
        line-height: 1.2em;
        & span.bold {
            font-weight: bold;
        }
        & a{
            color: #fff;
            text-decoration: underline;
        }
    }
    #header-feature-cta-wrap{
        padding-top: 50px; //50px;
        padding-bottom: 10px;
    }
    #header-feature-car {
        position: relative;
        text-align: center;
        img {
            margin-top: 50px;
            width: 100%;
            max-width: 100%;
            @media (max-width: 709px) {
                max-width: 400px;


            }
        }
    }

    #header-feature-cta-box {
        background: rgba(255,255,255,0.4);
        border-radius: 4px;
        padding: 5px;
        border: 1px solid rgba(255,255,255,0.1);
        box-shadow: rgba(0,0,0,0.3) 0px 1px 3px;
        h4 {
            margin-top: 5px;
            margin-bottom: 10px;
            text-align: center;
        }
        & .selectpicker {
            width: 100%;
            //height: 50px;
        }
        button.selectpicker {
            font-weight: bold;
        }
        & .form-group {
            margin-bottom: 5px;
        }
        & .form-group-btns {
            text-align: center;
            margin-bottom: 5px;
        }
        & .btn-cta {
            width: 100%; //150px;
            height: 50px;
            color: #fff;
            font-weight: 600;
            text-shadow: 0px -1px 0px rgba(0,0,0,0.5);
            border: 1px solid rgba(255,255,255,0.5);
            background-image: linear-gradient(to bottom, rgba(255,255,255,0.2) 0%,rgba(255,255,255,0) 100%);
            box-shadow: rgba(0,0,0,0.5) 0px 0px 5px;
            //letter-spacing: 0.05em;
            text-transform: uppercase;
            &:hover{
                background-image: linear-gradient(to bottom, rgba(255,255,255,0.3) 0%,rgba(255,255,255,0.1) 100%);
            }
            &:active{
                position: relative;
                top: 1px;
            }
        }
        & .btn-get-quote {
            //margin-left: 10px;
            background-color: #EC971F;
        }
        & .btn-learn-more {
            background-color: #447eb9;
        }
    }

}


// ------------------------------------------------








// -------------

@media (max-width:991px){
	.container{
		width: auto;
	}
}

#header {
    position: relative;
}

#header-top {
    background: @color-base;
    width: 100%;
    height: 40px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    color: #fff;
    line-height: 40px;
    //text-shadow: rgba(0, 0, 0, 0.4) 0px -1px 0px;
}

#header-top a{ color: #fff; text-decoration: underline; }

#header-main-container {
    min-height: 75px;
    background: #fff;
}
#header-main {
    z-index: 1001;
    position: relative;
    //position: absolute;
    //top: 0;
    //left: 0;
    width: 100%;
    min-height: 75px;
    //margin-top: 40px;
    background: rgba(255, 255, 255, 0.94);
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 0px 0px, rgba(0, 0, 0, 0.1) 0px 1px 4px;
    &.fixed {
        position: fixed !important;
        top: 0;
        left: 0;
    }
    & .logo {
        float: left;
        /*a {
            color: @dark-blue; //@color-base;//#002d57;
            text-transform: uppercase;
            font-size: 2.5em;
            font-weight: 900;
            //letter-spacing: 0.75px;
            text-decoration: none;
            line-height: 75px;
            & span {
                //color: @color-base;
            }
        }*/
    }
    min-height: 90px;
    .logo-new {
        display: block;
        padding-top: 18px;
        text-align: center;
        span {
            display: block;
        }
        span:nth-of-type(1) {
            margin-bottom: 10px;
            font-size: 2.2em; //2.5em;
            font-weight: 900;
            color: @color-base-dark;
            text-transform: uppercase;
        }
        span:nth-of-type(2) {
            font-size: 1.3em;
            font-weight: bold;
            color: @color-base;
        }
    }
    & nav#header-navigation {
        position: relative;
        float: right;
        margin-top: 25px; //17px;
        text-transform: uppercase;
        line-height: 40px;
        font-size: 13px;
        font-weight: bold;
        ul {
            display: block;
        }
        li {
            float: left;
        }
        a {
            display: block;
            margin-left: 5px;
            padding: 0 24px;
            border-radius: 4px;
            color: @dark-blue;
            text-decoration: none;
            &:hover {
                //background: #e6ebf1;
                color: @color-base;
            }
            &.active {
                color: @color-base;
            }
        }
        li.active a {
            background: #447eb9;
            color: #fff;
            text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.75);
            &:hover{
				box-shadow: inset rgba(0,0,0,0.2) 0px 0px 0px 40px;
            }
        }
    }
    .number-new {
        font-size: 1.5em;
        font-weight: bold;
        color: @color-base;
        line-height: 75px;
        i {
            color: @color-base-dark;
        }
    }
    .number-new {
        margin-top: 25px;
        button {
            float: left;
            width: 150px;
            font-weight: bold;
            text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.75);
        }
    }
    .quote-btn-new {
        margin-top: 25px;
        button {
            float: right;
            width: 150px;
            font-weight: bold;
            text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.75);
        }
    }
}

.toggleNavigation{
	display: none;
	position: absolute;
	top: 17px;
	right: 5px;
	font-size: 20px;
	width: 40px; height: 40px;
	background: #d9e5f2;
	border-radius: 3px;
	line-height: 40px;
	text-align: center;
	cursor: pointer;
}

.header-feature-text {
    text-align: center;
    text-shadow: 2px 1px 3px rgba( 0, 0, 0, 0.7 );
    /*&:after {
        display: block;
        width: 40px;
        margin: 20px auto 20px;
        //margin: 30px auto 30px;
        border-bottom: 2px solid #fff;
        content: "";
    }*/
    & h1 {
        margin-bottom: 10px;
        color: #fff;
        font-size: 3.2em;
        font-weight: 900;
        letter-spacing: 1px;
        line-height: 1.2em;
        text-transform: uppercase;
    }
    & h1 + span {
        display: inline-block;
        //padding: 5px 10px;
        //border: 2px solid #fff;
        //background: @color-base;
        color: #fff;
        font-size: 1.6em;
        font-weight: 400;
        & span {
            font-weight: 900;
        }
        & a{
            color: #fff;
            text-decoration: underline;
        }
    }
}



#header-cta {
    background: @color-base; //#fbfdfe;
    //background: rgba(255, 255, 255, 0.50);
    //background-image: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
    //position: absolute;
    //bottom: 0;
    width: 100%;
    min-height: 75px;
    line-height: 75px;
    border-top: 2px solid #fff;
    & form {
        text-align: center;
    }
    & .btn-cta {
        width: 100px;
    }
}

#content {
    background: #fff;
}

#footer {
    width: 100%;
    padding-bottom: 20px;
    background: @color-base;
    border-top: 1px solid #1a4268;
    line-height: 58px;
    color: #fff;
    a, a:hover {
        color: #fff;
    }
}



.nav > li > a{
	padding-top: 20px;
	padding-bottom: 20px;
}

section {
    &:first-of-type {
        padding-top: 30px;
    }
    &:last-of-type {
        margin-bottom: 30px;
    }
    &:not(:last-of-type) {
        padding-bottom: 30px;
        margin-bottom: 30px;
        border-bottom: 3px dotted #d8e0e9;
    }
    & h3 {
        margin-bottom: 30px;
        text-align: center;
        font-weight: bold;
        /*&:after {
            display: block;
            width: 80px;
            margin: 15px auto 0;
            border-bottom: 3px dotted #d8e0e9;
            content: "";
        }*/
    }
    & p {
        margin-bottom: 0;
    }
    &.section-lead {
        text-align: center;
        text-transform: capitalize;
        p {
            //font-size: 1.3em;
        }
    }
    &.section-benefits {
        text-align: center;
        & .benefits-item {
            & i {
                display: inline-block;
                position: relative;
                width: 100px;
                height: 100px;
                border: 2px solid @color-base;
                border-radius: 50%;
                background: @color-base;
                color: #fff;
                font-size: 40px;
                line-height: 100px;
                text-align: center;
                vertical-align: middle;
            }
            & h4, h5 {
                margin: 25px 0 10px;
                color: #364250;
                font-weight: bold;
            }
        }
    }
    &.section-how-it-works {
        & .how-it-works-steps {
            & ul {
                & li {
                    text-align: left;
                    &:not(:last-of-type):after {
                        display: block;
                        width: 80px;
                        margin: 20px auto;
                        border-bottom: 3px dotted #d8e0e9;
                        content: "";
                    }
                    & .steps-icon {
                        float: left;
                        padding-bottom: 10px;
                        margin: 10px 18px 0 0;
                        color: @color-base;
                        text-align: center;
                        font-weight: bold;
                        & i {
                            display: block;
                            margin-bottom: 8px;
                            font-size: 2em;
                        }
                    }
                    & h4, h5 {
                        color: @dark-blue;
                        margin-bottom: 10px;
                        text-transform: capitalize;
                    }
                }
            }
        }
        & .how-it-works-testimonials {
            /*.testimonial {
                text-align: left;
                .tText {
                    background: @color-base;
                    border-radius: 3px;
                    padding: 20px;
                    margin-bottom: 30px;
                    position: relative;
                    color: #fff;
                    text-align: left;
                }

                .tText:after {
                    content: " ";
                    position: absolute;
                    width: 0;
                    height: 0;
                    border: 14px solid transparent;
                    border-top-color: @color-base;
                    bottom: -28px;
                    left: 20px;
                }

                section:nth-of-type(odd) .testimonial .tText {
                    background: @color-base;
                }

                section:nth-of-type(odd) .testimonial .tText:after {
                    border-top-color: @color-base;
                }

                .photo {
                    width: 48px;
                    height: 48px;
                    float: left;
                    margin-left: -58px;
                    border-radius: 50%;
                    background-size: cover;
                    background-color: #222;
                    box-shadow: inset rgba(0, 0, 0, 0.2) 0px 0px 0px 1px;
                }

                .tAuthor {
                    padding-left: 68px;
                    line-height: 24px;
                }

                .name {
                    display: block;
                }

                .car {
                    display: block;
                    font-weight: 300;
                }
            }*/
        }
    }
    &.section-faq {
        text-align: left;
        li {
            display: block;
            &:not(:last-of-type) {
                //margin-bottom: 30px;
            }
            &:not(:last-of-type):after {
                display: block;
                width: 80px;
                margin: 20px auto;
                border-bottom: 3px dotted #d8e0e9;
                content: "";
            }
            & strong:before, h5:before {
                display: inline-block;
                margin-right: 5px;
                margin-bottom: 5px;
                color: @color-base;
                content: "Q.";
            }
        }
    }
    &.section-about {
        & .about-description {
            text-align: left;
            li {
                &:not(:last-of-type) {
                    margin-bottom: 30px;
                }
                /*&:last-of-type {
                    margin-bottom: 10px;
                }
                &:not(:last-of-type):after {
                    display: block;
                    width: 80px;
                    margin: 20px auto;
                    border-bottom: 3px dotted #d8e0e9;
                    content: "";
                }*/
                h5 {
                    margin-bottom: 5px;
                    color: @color-base-dark;
                }
            }
        }
        & .about-contact {
            h5 {
                margin-bottom: 5px;
            }
            .row {
                margin-left: -5px;
                margin-right: -5px;
            }
            div[class*='col'] {
                padding: 0 5px;
            }
            .call-us-box {
                margin-bottom: 20px;
            }
            button {
                height: 50px;
                font-weight: bold;
            }

            label {
                margin-bottom: 6px;
                line-height: 1;
            }
            .input-group-addon {
                padding-right: 0;
                border: 1px solid fade( @color-base-dark, 30% );
                border-right: 0;
            }
            .form-control, .selectpicker {
                border: 1px solid fade( @color-base-dark, 30% );
                border-left: 0;
            }
            .form-control[type=text], .form-control[type=email], .form-control[type=tel] {
                height: 35px;
            }
            .input-group-addon {
                background: #fff;
                color: @color-base;
            }
            .input-group-addon i {
                font-size: 1.2em;
            }
        }
    }
    &.section-final {
        h3:after {
            content: none;
        }
    }
}

.m--how-it-works {

    .step {
        text-align: left;
        .icon {
            float: left;
            margin: 45px 18px 20px 0;
            color: @color-base;
            text-align: center;
            font-weight: bold;
            & i {
                display: block;
                font-size: 2em;
            }
        }
        h5 {
            margin-bottom: 10px;
            text-transform: capitalize;
            i {
                display: inline-block;
                margin-right: 10px;
                color: @color-base;
                font-size: 1.5em;
            }
            span:nth-of-type(2) {
                color: @color-base-dark;
            }
        }
    }

    .step-sep {
        margin-bottom: 30px;
        /*&:after {
            display: block;
            width: 80px;
            margin: 20px auto;
            border-bottom: 3px dotted #d8e0e9;
            content: "";
        }*/
    }

}

.m-testimonials {

    .slick-slider
    {
        user-select: text;
    }

    .testimonials {
        //margin-bottom: 20px;
        .testimonial {

            margin-bottom: 10px;

            @media ( min-width: @screen-sm-min )
            {
                margin-bottom: 0;
            }

            .photo {
                height: 80px;
                img {
                    position: absolute;
                    top: 0;
                    left: 150px;
                    display: block;
                    width: 90px;
                    height: 90px;
                    margin: 0 auto;
                    z-index: 700;
                }
            }
            .name {
                font-weight: bold;
            }
            .car {
                // font-weight: 300;
            }
            .content {
                background: @color-base;
                border-radius: 3px;
                padding: 20px;
                margin-bottom: 20px;
                position: relative;
                color: #fff;
                text-align: left;
                height: auto;

                @media ( min-width: @screen-sm-min )
                {
                    height: 157px;
                }
            }

            .content:after {
                content: " ";
                position: absolute;
                width: 0;
                height: 0;
                border: 14px solid transparent;
                border-top-color: @color-base;
                bottom: -28px;
                left: 20px;
            }
        }
    }

    .buttons {
        display: none;
        text-align: center;
        button {
            padding: 5px;
            width: 30px;
            height: 30px;
            border: none;
        }
        @media (min-width: @screen-sm-min) {
           display: block;
        }
    }

}





.quoteWrap h2 {
    color: #fff;
    background: rgba(0, 0, 0, 0.3);
    background: rgba(255, 255, 255, 0.8);
    padding: 20px;
    margin: -11px -11px 10px -11px;
    border-radius: 3px 3px 0px 0px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: rgba(255, 255, 255, 0.2) 0px 1px 0px 0px;
}

.quoteWrap {
    float: right;
    width: 50%;
    min-width: 520px;
    padding: 10px;
    background: rgba(0, 0, 0, 0.5);
    background: rgba(255, 255, 255, 0.8);
    /* background-image: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.3) 100%);
    box-shadow: inset rgba(0,0,0,0.3) 0px 0px 0px 1px, rgba(0,0,0,0.3) 0px 1px 10px;*/
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 3px;
    text-align: center;
}

.quoteBox {
    position: relative;
}

.quoteBox h2 {
    color: #222;
}

.quoteBox .instructions {
    color: #fff;
    text-shadow: rgba(0, 0, 0, 0.4) 0px 1px 2px, rgba(0, 0, 0, 0.4) 0px 1px 10px;
    font-size: 1.5em;
    font-weight: 500;
    padding: 0 15px;
}

.quoteForm {
    padding-top: 5px;
}

.quoteForm div[class*='col-'] {
    padding: 0 5px 10px 5px;
}

@inputTextBg: rgba(255, 255, 255, 0.9);
@inputText: #222;
@inputBorder: #fff;

.selectCar {
    min-height: 56px;
}

.selectCar .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
    width: 100%;
}

.selectCar .btn-default {
    color: @inputText;
    background: @inputTextBg;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
    border-color: @inputBorder;
    /* padding: 10px 16px; */
    /* box-shadow: rgba(0,0,0,0.2) 0px 1px 5px; */
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding-top: 12px;
    padding-bottom: 12px;
}

.selectCar .btn-default:before {
    content: " ";
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 33px;
    background: #fff;
}

.selectCar .btn-default:active,
.selectCar .open > .dropdown-toggle.btn-default {
    background: @inputTextBg;
    border-color: @inputBorder;
    color: @inputText;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
}

.selectCar .dropdown-menu {
    background: @inputTextBg;
    border-color: @inputBorder;
}

.selectCar .dropdown-menu > li > a {
    color: @inputText;
}

.selectCar .dropdown-menu > li > a:hover {
    color: @inputText;
    background: rgba(0, 0, 0, 0.05);
}

.selectCar .dropdown-menu > li > a:focus {
    outline: none;
    background: rgba(255, 255, 255, 0.05);
}

.quoteForm .form-control {
    height: auto;
}

.quoteForm .textInput {
    width: 100%;
    height: 42px;
    padding-left: 50px;
    color: @inputText;
    border: 1px solid #fff;
    background: rgba(255, 255, 255, 0.9);
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    border-radius: 4px;
}

.quoteForm .textInput:focus {
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
}

.quoteForm .textInputIcon {
    position: relative;
    display: block;
}

.quoteForm .textInputIcon:before {
    content: attr(data-icon);
    font-family: 'FontAwesome';
    font-style: normal;
    font-weight: normal;
    line-height: 40px;
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    transform: translate(0, 0);

    text-align: center;
    position: absolute;
    top: 1px;
    left: 1px;
    bottom: 1px;
    width: 40px;
    background: rgba(255, 255, 255, 0.6);
    box-shadow: inset rgba(123,137,152,0.2) -1px 0px 0px 0px;
    color: #7b8998;
    pointer-events: none;
}

.quoteForm .row {
    margin: 0;
}

.quoteSubmit {
    border-radius: 3px;
    background: #e8a52d;
    background: linear-gradient(to bottom, #e9a52c 0%, #e49125 100%);
    /*
      border: 1px solid rgba(255,255,255,0.06);
      border-top-color: rgba(255,255,255,0.5);
    */
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: rgba(0, 0, 0, 0.04) 0px 1px 2px, inset rgba(255, 255, 255, 0.1) 0px 1px 0px;
    color: #fff;
    text-shadow: rgba(0, 0, 0, 0.2) 0px -1px 0px;
    width: 100%;
    width: ~"calc(100% - 10px)";
    margin: 0 auto;
    display: block;
    line-height: 62px;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
}

/* Responsive header */
@media screen and (max-width:900px){
	
	.toggleNavigation{ display: block; }
	
	#header-top{
		height: auto;
		text-align: center;
		& .pull-left, & .pull-right{ float: none !important; display: block; }
	}
	
	#header-main {
	    position: relative;
	    &.fixed {
	        position: relative !important;
	    }
	    & .logo {
	        float: none;
	        text-align: center;
	        padding-right: 45px;
	        a {
	            display: inline-block;
	            font-size: 20px;
	        }
	    }
	    & nav#header-navigation {
	        float: none;
	        margin-top: 0;
	        text-align: center;
	        padding-bottom: 10px;
	        display: none;
	        
	        &.showNavigation{
		        display: block;
	        }
	        
	        ul {
	            display: block;
	        }
	        li {
	            float: none;
	            display: block;
	        }
	        a {
	            margin-left: 0;
	        }
	    }
	}

    /*#header-feature {
        height: auto;
    }*/
	
}

@media (max-width:991px){
	.benefits-item{
		padding-bottom: 30px;
	}
}

@media (max-width:767px){
	#section-about,
	.contact-form,
	.services-testimonials{
		padding-top: 50px;
	}
	
	#header-feature #header-feature-dark { padding: 40px 0; }
	
}