ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}
* {
  outline: none;
}
html {
  height: 100%;
}
body {
  background: #fff;
  font-family: "Open Sans", "Roboto", Tahoma, sans-serif;
  font-size: 14px;
  color: #333333;
  line-height: 1.7;
  letter-spacing: normal;
}
@media (min-width: 768px) {
  body {
    font-size: 13px;
  }
}
@media (min-width: 992px) {
  body {
    font-size: 13px;
  }
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  color: #333333;
  font-weight: bold;
}
h1.heading,
h2.heading,
h3.heading,
h4.heading,
h5.heading,
h6.heading {
  margin-bottom: 30px;
  color: #364250;
  text-align: center;
  font-weight: bold;
}
h1.heading:after,
h2.heading:after,
h3.heading:after,
h4.heading:after,
h5.heading:after,
h6.heading:after {
  display: block;
  width: 80px;
  margin: 15px auto 0;
  border-bottom: 3px dotted #d8e0e9;
  content: "";
}
h1 span,
h2 span,
h3 span,
h4 span,
h5 span,
h6 span {
  color: #447eb9;
}
h1 {
  font-size: 2.6em;
}
h2 {
  font-size: 2.2em;
}
h3 {
  font-size: 1.9em;
}
h4 {
  font-size: 1.4em;
}
h5 {
  font-size: 1.2em;
}
h6 {
  font-size: 1em;
}
p {
  margin: 0;
}
a:hover,
a:focus {
  outline: none;
  text-decoration: none;
}
.container {
  width: 100%;
  max-width: 1200px;
}
.example {
  /* rules for mobile vertical (< 480) */
}
@media (min-width: 768px) {
  .example {
    /* rules for tablet (768 > 992) */
  }
}
@media (min-width: 992px) {
  .example {
    /* rules for desktop (992 > 1200) */
  }
}
@media (min-width: 1200px) {
  .example {
    /* rules for large (> 1200) */
  }
}
.separator {
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 3px dotted #d8e0e9;
}
.margin-bottom-none {
  margin-bottom: 0;
}
.margin-bottom-10 {
  margin-bottom: 10px;
}
.margin-bottom-20 {
  margin-bottom: 20px;
}
.margin-bottom-30 {
  margin-bottom: 30px;
}
.btn-primary {
  padding: 10px;
  background: #447eb9;
  color: #fff;
}
.btn-primary:hover {
  background: #568bc1;
}
input[type=text],
input[type=email],
input[type=tel],
textarea {
  -webkit-appearance: none;
}
.form-group {
  padding: 0;
  margin: 0 0 15px;
}
.form-error label {
  color: #C5635C;
}
.form-group.has-error .input-group > .input-group-addon {
  border-right: 0;
}
label {
  display: block;
  color: #333333;
  text-transform: capitalize;
}
label span.label-desc {
  display: inline-block;
  font-weight: 400;
  text-transform: capitalize;
}
.has-error label span.label-desc {
  color: #C5635C;
}
.input-error {
  display: none;
  margin-top: 5px;
}
.has-error .input-error {
  color: #C5635C;
}
.input-group-addon {
  background: #447eb9;
  color: #fff;
}
.form-control {
  font-size: inherit;
}
.form-control:focus {
  border: 1px solid #CCC;
  box-shadow: none;
}
.form-control:not(.selectpicker) {
  box-shadow: none;
}
.form-control:not(.selectpicker, textarea) {
  line-height: 2em;
}
.form-control:focus {
  background: #ffffff;
  box-shadow: none;
  font-size: inherit;
}
.has-error .form-control {
  box-shadow: none;
}
.has-error .form-control:focus {
  box-shadow: none;
}
.has-error .form-control::-moz-placeholder {
  opacity: 1;
}
.form-control[type=text],
.form-control[type=email] {
  height: 40px;
}
.selectpicker {
  width: 100%;
  border: 1px solid #fff;
  background: #fff;
  color: #364250;
}
.selectpicker .filter-option {
  overflow: hidden;
}
.input-group-btn {
  font-size: inherit;
}
.input-group-btn:last-child > .btn {
  margin-left: 0;
}
.btn-group.open .dropdown-toggle {
  box-shadow: none;
}
.open > .dropdown-toggle.btn-default {
  background-color: #FFF;
  border-color: #CCC;
}
.input-group-btn:last-child > .btn {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.btn-default {
  border: 1px solid #fff;
  background-color: #fff;
  color: #364250;
}
span.twitter-typeahead {
  width: 100%;
}
.input-group span.twitter-typeahead {
  display: block !important;
}
.input-group span.twitter-typeahead .tt-menu {
  top: 32px !important;
}
.input-group.input-group-lg span.twitter-typeahead .tt-menu {
  top: 44px !important;
}
.input-group.input-group-sm span.twitter-typeahead .tt-menu {
  top: 28px !important;
}
.tt-query {
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.tt-hint {
  color: #999999;
}
.tt-menu {
  width: 300px;
  margin-top: 4px;
  padding: 4px 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}
.tt-suggestion {
  padding: 3px 20px;
  line-height: 24px;
}
.tt-suggestion.tt-cursor {
  color: #fff;
  background-color: #0097cf;
}
.tt-suggestion:hover {
  cursor: pointer;
  background-color: #447eb9;
  color: #fff;
}
.tt-suggestion p {
  margin: 0;
}
.dropdown-menu {
  font-size: inherit;
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select:focus,
  textarea:focus,
  input:focus {
    font-size: 16px;
  }
}
.cta-button {
  display: inline-block;
  padding: 10px 30px;
  border-radius: 4px;
  color: #ffffff;
  font-weight: 700;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.4);
  background: #ec971f linear-gradient(to bottom, rgba(255, 255, 255, 0.1) 0, rgba(255, 255, 255, 0) 100%);
  box-shadow: 0 -1px #c77c11 inset, 0 1px #f0ad4e inset;
  border: 1px solid #c77c11;
  border-top: 1px solid #eea237;
  text-transform: uppercase;
}
.cta-button:hover {
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0.1) 100%);
  color: #fff;
}
.cta-button:active {
  position: relative;
  top: 1px;
  color: #fff;
}
.cta-button:focus {
  color: #fff;
}
.cta-button-blue {
  padding: 10px 30px;
  border-radius: 4px;
  color: #ffffff;
  font-weight: 700;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.4);
  background: #447eb9 linear-gradient(to bottom, rgba(255, 255, 255, 0.1) 0, rgba(255, 255, 255, 0) 100%);
  box-shadow: 0 -1px #366594 inset, 0 1px #6898c8 inset;
  border: 1px solid #366594;
  border-top: 1px solid #568bc1;
}
.cta-button-white {
  color: #333333;
  text-shadow: none;
  background: #FFF;
  box-shadow: none;
  border: 1px solid rgba(51, 51, 51, 0.3);
  border-top: 1px solid rgba(51, 51, 51, 0.3);
}
.cta-button-white:active,
.cta-button-white:focus {
  color: #333333;
}
.cta-button-white:hover {
  color: #333333;
  background: #F7F7F7;
}
.modal {
  webkit-overflow-scrolling: none;
}
.modal-open {
  position: fixed;
  width: 100%;
  overflow: hidden;
}
.modal-backdrop {
  z-index: 0;
}
.modal-dialog {
  width: auto;
  max-width: 800px;
}
.modal-body .close {
  font-size: 3em;
  line-height: inherit;
}
.modal-body h4,
.modal-body h5 {
  margin-top: 20px;
  color: #447eb9;
}
.modal-body h4:after,
.modal-body h5:after {
  display: block;
  margin-bottom: 20px;
  padding-bottom: 5px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  content: "";
}
.modal-body p.note {
  font-weight: 300;
  text-align: center;
}
.modal-body .row {
  margin-left: -5px;
  margin-right: -5px;
}
.modal-body div[class*='col'] {
  padding: 0 5px;
}
.modal-body .modal-section:last-of-type {
  padding-bottom: 20px;
}
.modal-body .modal-section:not(:last-of-type) {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 3px dotted #d8e0e9;
}
.modal-body .modal-sep {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 3px dotted #d8e0e9;
}
.modal-body .modal-btn {
  display: block;
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: center;
}
.modal-body .selectpicker {
  height: 40px;
  border: 1px solid #447eb9;
  box-shadow: none;
  background: #fff;
}
.modal-body .form-control[type=text],
.modal-body .form-control[type=email],
.modal-body .form-control[type=tel] {
  height: 40px;
}
.modal-body .input-group-addon i {
  font-size: 1.2em;
}
.modal-body .input-group-btn button,
.modal-body .input-group-btn .dropdown-toggle.btn-default {
  height: 40px;
  background-color: #447eb9;
  border: 1px solid #447eb9;
  color: #ffffff;
}
.modal-body .dropdown-menu > .active > a,
.modal-body .dropdown-menu > .active > a:focus,
.modal-body .dropdown-menu > .active > a:hover {
  background-color: #447eb9;
}
.modal-body .btn-primary {
  max-width: 400px;
  font-weight: bold;
  text-transform: uppercase;
}
.modal-body .modal-body .modal-body .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
  width: 100%;
}
.modal-body .quote-form-section {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 3px dotted #d8e0e9;
}
.modal-body .quote-form-section:last-of-type {
  background: black;
  margin-bottom: 20px;
}
.modal-body .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
  width: 100%;
}
.modal-header h3,
.modal-header h4,
.modal-header h5,
.modal-header h6 {
  text-align: center;
}
.modal-body .form-horizontal,
.modal-body .form-group {
  margin-left: 0px;
  margin-right: 0px;
}
.modal-body .icon-password {
  font-size: 5.625em;
  line-height: 0px;
  margin-top: 35px;
  display: block;
}
.modal-body .icon-repeat {
  font-size: 3.125em;
  line-height: 0px;
  display: block;
  margin-top: 20px;
}
/*.modal-body .form-control{
    height: auto;
}*/
/*
.modal-body button {
    font-size: 1.125em;
    font-weight: 600;
    padding: 10px;
}*/
#js--modal-dynamic .modal-body {
  max-height: 300px;
  overflow-y: scroll;
}
#header {
  padding-top: 100px;
}
@media (min-width: 768px) {
  #header {
    padding-top: 75px;
  }
}
#header-bar-final {
  z-index: 2000;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100px;
  background: #ffffff;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.3);
}
@media (min-width: 768px) {
  #header-bar-final {
    height: 75px;
  }
}
#header-bar-final .logo {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 2em;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
}
@media (max-width: 320px) {
  #header-bar-final .logo {
    font-size: 1.7em;
  }
}
@media (min-width: 768px) {
  #header-bar-final .logo {
    float: left;
    text-align: left;
    line-height: 1.2;
    padding: 12px 0;
  }
}
#header-bar-final .logo a,
#header-bar-final .logo a:hover {
  color: #333333;
}
#header-bar-final .logo span {
  display: block;
  font-size: 0.51em;
  font-weight: 400;
  color: #447eb9;
  letter-spacing: 0.3px;
}
#header-bar-final .navigation {
  text-align: center;
}
@media (min-width: 768px) {
  #header-bar-final .navigation {
    float: right;
    text-align: left;
  }
}
@media (max-width: 320px) {
  #header-bar-final .navigation .cta-button {
    padding: 10px 15px;
  }
}
@media (min-width: 768px) {
  #header-bar-final .navigation .cta-button {
    margin: 15px 0;
  }
}
#header-bar-final .navigation ul {
  font-size: 0;
}
#header-bar-final .navigation ul li {
  display: inline-block;
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  /*a.cta-button {
                    padding: 10px 30px;
                    border-radius: 4px;
                    background: @color-base;
                    color: #ffffff;
                    text-shadow: 0 -1px 0 rgba(0,0,0,0.4);
                    background: #EC971F linear-gradient(to bottom, rgba(255, 255, 255, 0.1) 0, rgba(255, 255, 255, 0) 100%);
                    box-shadow: 0 -1px darken( #EC971F, 10% ) inset, 0 1px lighten( #EC971F, 10% ) inset;
                    border: 1px solid darken( #EC971F, 10% );
                    border-top: 1px solid lighten( #EC971F, 5% );
                }
                a.cta-button:hover {
                    background: darken( @color-base, 10% );
                }
                a.cta-highlight {
                    background: #EC971F linear-gradient(to bottom, rgba(255, 255, 255, 0.2) 0, rgba(255, 255, 255, 0) 100%);
                }*/
}
#header-bar-final .navigation ul li:last-of-type {
  margin-left: 15px;
}
@media (min-width: 768px) {
  #header-bar-final .navigation ul li.cta {
    display: none;
  }
}
#header-bar-final .navigation ul li.cta-visible {
  display: inline-block;
}
#header-bar-container {
  min-height: 90px;
}
#header-bar-container #header-bar {
  z-index: 1001;
  position: relative;
  width: 100%;
  min-height: 90px;
  background: rgba(255, 255, 255, 0.94);
  background-image: linear-gradient(to bottom, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 0px 0px, rgba(0, 0, 0, 0.1) 0px 1px 4px;
  /* .button-call {
            @media (min-width: @screen-sm-min) {
                float: left;
            }
        }
        .button-quote {
            @media (min-width: @screen-sm-min) {
                float: right;
            }
        } */
}
@media (min-width: 768px) {
  #header-bar-container #header-bar.fixed {
    position: fixed !important;
    top: 0;
    left: 0;
  }
}
#header-bar-container #header-bar .logo {
  display: block;
  padding-top: 18px;
  font-family: "Roboto", Tahoma, sans-serif;
  text-align: center;
  line-height: 1;
}
#header-bar-container #header-bar .logo span {
  display: block;
}
#header-bar-container #header-bar .logo span:nth-of-type(1) {
  font-size: 2.2em;
  color: #333333;
  text-transform: uppercase;
}
#header-bar-container #header-bar .logo span:nth-of-type(1):after {
  display: block;
  width: 350px;
  margin: 4px auto;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  content: "";
}
@media (max-width: 768px) {
  #header-bar-container #header-bar .logo span:nth-of-type(1) {
    font-size: 1.8em;
  }
}
#header-bar-container #header-bar .logo span:nth-of-type(2) {
  font-size: 1.3em;
  font-weight: normal;
  color: #447eb9;
}
@media (max-width: 768px) {
  #header-bar-container #header-bar .logo span:nth-of-type(2) {
    font-size: 1.1em;
  }
}
#header-bar-container #header-bar .button-call,
#header-bar-container #header-bar .button-quote {
  width: 150px;
  font-weight: bold;
  text-transform: uppercase;
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.75);
}
#header-bar-container #header-bar .button-call i,
#header-bar-container #header-bar .button-quote i {
  display: inline;
}
@media (max-width: 768px) {
  #header-bar-container #header-bar .button-call,
  #header-bar-container #header-bar .button-quote {
    width: auto;
  }
}
#header-bar-container #header-bar .headerButtons {
  position: relative;
}
@media (min-width: 768px) {
  #header-bar-container #header-bar .headerButtons .button-call {
    position: absolute;
    left: 0;
    top: -53px;
  }
  #header-bar-container #header-bar .headerButtons .button-quote {
    position: absolute;
    right: 0;
    top: -53px;
  }
}
@media (max-width: 768px) {
  #header-bar-container #header-bar .headerButtons {
    padding: 10px 0;
    text-align: center;
  }
}
#header-feature {
  width: 100%;
  padding: 20px 0;
  background: #222;
  background-size: cover;
}
@media (min-width: 768px) {
  #header-feature {
    background: transparent;
    height: 500px;
    padding: 0;
  }
}
#header-feature.header-feature-vcentre #header-feature-gradient {
  display: table;
}
#header-feature.header-feature-vcentre .container {
  display: table-cell;
  height: 100%;
  vertical-align: middle;
  text-align: center;
}
#header-feature #header-feature-gradient {
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to bottom, rgba(21, 39, 57, 0.6), rgba(21, 39, 57, 0.3));
}
#header-feature #header-feature-text {
  display: inline-block;
  margin-bottom: 25px;
  width: auto;
  text-align: center;
}
#header-feature #header-feature-text h1 {
  margin-bottom: 20px;
  color: #fff;
  font-size: 2.2em;
  font-weight: 900;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.7);
  /*&:after {
                display: block;
                width: 80px;
                margin: 20px auto;
                border-bottom: 2px solid rgba( 255, 255, 255, 1 );
                content: "";
            }*/
}
@media (min-width: 768px) {
  #header-feature #header-feature-text h1 {
    font-size: 3.2em;
  }
}
#header-feature #header-feature-text span.sub {
  display: inline;
  background: rgba(0, 0, 0, 0.25);
  box-shadow: 10px 0 10px rgba(0, 0, 0, 0.25), -10px 0 10px rgba(0, 0, 0, 0.25);
  box-decoration-break: clone;
  color: #fff;
  font-size: 1.6em;
  font-weight: 300;
  line-height: 1.5em;
  text-shadow: 0 1px 10px #000000;
}
#header-feature #header-feature-text span.sub span.bold {
  font-weight: bold;
}
#header-feature #header-feature-text span.sub a {
  color: #fff;
  text-decoration: underline;
}
#header-feature #header-feature-cta {
  margin: 0 auto;
  padding: 5px;
  width: 300px;
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 3px;
}
@media (min-width: 768px) {
  #header-feature #header-feature-cta {
    width: 400px;
  }
}
#header-feature #header-feature-cta span.cta-heading {
  display: block;
  margin-bottom: 8px;
  font-size: 1.6em;
  font-weight: bold;
  color: #FFF;
  text-align: center;
  text-shadow: 2px 1px 3px rgba(0, 0, 0, 0.7);
}
#header-feature #header-feature-cta .selectpicker {
  width: 100%;
}
#header-feature #header-feature-cta .js-car-make .filter-option:before,
#header-feature #header-feature-cta .js-car-model .filter-option:before,
#header-feature #header-feature-cta .js-car-badge .filter-option:before {
  display: inline-block;
  width: 50px;
  height: 100%;
  margin-right: 10px;
  border-right: 1px solid rgba(0, 0, 0, 0.5);
  font-weight: bold;
  content: "Make";
}
#header-feature #header-feature-cta .js-car-model .filter-option:before {
  content: "Model";
}
#header-feature #header-feature-cta .js-car-badge .filter-option:before {
  content: "Series";
}
#header-feature #header-feature-cta .form-group {
  margin-bottom: 8px;
}
#header-feature #header-feature-cta .form-group-btns {
  text-align: center;
}
#header-feature #header-feature-cta .btn-cta {
  width: 100%;
  height: 50px;
  color: #fff;
  font-weight: 600;
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.5);
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
  box-shadow: rgba(0, 0, 0, 0.8) 0px 0px 16px;
  text-transform: uppercase;
}
#header-feature #header-feature-cta .btn-cta:hover {
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0.1) 100%);
}
#header-feature #header-feature-cta .btn-cta:active {
  position: relative;
  top: 1px;
}
#header-feature #header-feature-cta .btn-get-quote {
  background-color: #EC971F;
}
#header-feature #header-feature-cta .btn-learn-more {
  background-color: #447eb9;
}
#header-feature #header-heading {
  text-align: center;
}
#header-feature #header-heading h1 {
  font-weight: 800;
  text-shadow: rgba(0, 0, 0, 0.5) 0px 1px 2px, #000000 0px 1px 80px, #000000 0px 1px 60px;
}
#header-feature #header-heading h1 + span {
  display: block;
  color: #fff;
  font-size: 1.5em;
  font-weight: 500;
}
#header-feature-final {
  position: relative;
  height: 100%;
  padding-bottom: 20px;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.3);
  line-height: 1.5;
}
@media (min-width: 768px) {
  #header-feature-final {
    min-height: 650px;
    background: url('../img/header/nobg/background.jpg') no-repeat center bottom;
    background-size: cover;
  }
}
#header-feature-final .gradient {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
  background: transparent linear-gradient(to bottom, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.6)) repeat scroll 0% 0%;
}
#header-feature-final .container {
  height: 100%;
  padding-top: 20px;
}
#header-feature-final .container .row {
  height: 100%;
}
#header-feature-final .important-message {
  border: 1px solid #366594;
  border-radius: 4px;
  padding: 8px;
  margin-bottom: 10px;
  background: #447eb9;
  color: #FFF;
}
#header-feature-final .important-message strong {
  font-size: 1.1em;
}
#header-feature-final h1 {
  margin-bottom: 10px;
  font-size: 2em;
  font-weight: 700;
  text-transform: uppercase;
}
#header-feature-final h1 span {
  color: #333333;
}
@media (max-width: 768px) {
  #header-feature-final h1 {
    line-height: 1.2;
  }
}
@media (min-width: 768px) {
  #header-feature-final h1 {
    margin-top: 10px;
  }
}
#header-feature-final #header-feature-left,
#header-feature-final #header-feature-right {
  position: relative;
  height: 100%;
}
@media (min-width: 768px) {
  #header-feature-final #header-feature-left,
  #header-feature-final #header-feature-right {
    height: 630px;
  }
}
#header-feature-final #header-feature-benefits {
  margin-bottom: 20px;
  margin-left: 10px;
}
#header-feature-final #header-feature-benefits li {
  margin-bottom: 15px;
  /*i {
                float: left;
                margin-top: 15px;
                margin-right: 10px;
                color: @color-base;
                font-size: 1.5em;
            }*/
}
#header-feature-final #header-feature-benefits li i {
  margin-right: 5px;
  color: #447eb9;
  font-size: 1.1em;
}
#header-feature-final #header-feature-benefits li span {
  font-size: 1.1em;
  font-weight: 700;
}
#header-feature-final #header-feature-benefits li strong {
  font-size: 1.2em;
}
#header-feature-final .learn-more {
  text-align: center;
}
@media (min-width: 768px) {
  #header-feature-final .learn-more {
    text-align: center;
  }
}
#header-feature-final #header-feature-car {
  position: relative;
  margin-top: 20px;
  margin-bottom: 10px;
}
@media (min-width: 768px) {
  #header-feature-final #header-feature-car {
    position: absolute;
    bottom: 0;
    left: 0;
    margin-bottom: 0;
  }
}
#header-feature-final #header-feature-car img {
  width: 100%;
  max-width: 100%;
}
#header-feature-final #header-feature-form {
  background: rgba(255, 255, 255, 0.7);
  border-radius: 4px;
  padding: 10px;
  border: 1px solid rgba(51, 51, 51, 0.3);
}
#header-feature-final .request-quote-form h1 {
  margin-top: 0;
}
.request-quote-form {
  /*.input-group-btn button, .input-group-btn .dropdown-toggle.btn-default {
        height: 40px;
        background-color: @color-base;
        border: 1px solid @color-base;
        color: rgb(255, 255, 255);

    }*/
}
.request-quote-form p.note {
  text-align: center;
}
.request-quote-form .row {
  margin-left: -5px;
  margin-right: -5px;
  /*@media (min-width: @screen-sm-min) {
            div:last-of-type {
                padding-left: 0;
            }
        }*/
}
.request-quote-form div[class*='col'] {
  padding: 0 5px;
}
.request-quote-form .form-group {
  margin-bottom: 12px;
}
.request-quote-form label {
  margin-bottom: 6px;
  line-height: 1;
}
.request-quote-form p.separator {
  height: 1px;
  border-bottom: 3px dotted rgba(68, 126, 185, 0.3);
  margin-bottom: 20px;
  padding-bottom: 10px;
}
.request-quote-form p.heading {
  padding-bottom: 3px;
  margin-bottom: 12px;
  border-bottom: 1px solid rgba(68, 126, 185, 0.3);
  color: #447eb9;
  font-weight: 700;
  text-transform: capitalize;
  line-height: 1;
  font-size: 1.1em;
}
@media (max-width: 768px) {
  .request-quote-form p.heading {
    line-height: 1.5;
  }
}
.request-quote-form p.heading span {
  color: #333333;
  font-size: 0.7em;
  font-weight: 400;
  text-transform: none;
}
.request-quote-form select {
  padding-left: 8px;
}
.request-quote-form .selectpicker {
  height: 35px;
  border: 1px solid #ccc;
  box-shadow: none;
  background: #fff;
}
.request-quote-form .dropdown-menu > .selectpicker {
  height: 100%;
}
.request-quote-form .input-group-addon {
  padding-right: 0;
  border: 1px solid rgba(51, 51, 51, 0.3);
  border-right: 0;
}
.request-quote-form .form-control,
.request-quote-form .selectpicker {
  border: 1px solid rgba(51, 51, 51, 0.3);
  border-left: 0;
}
.request-quote-form .form-control[type=text],
.request-quote-form .form-control[type=email],
.request-quote-form .form-control[type=tel] {
  height: 35px;
}
.request-quote-form .input-group-addon {
  background: #fff;
  color: #447eb9;
}
.request-quote-form .input-group-addon i {
  font-size: 1.2em;
}
.request-quote-form .dropdown-menu > .active > a,
.request-quote-form .dropdown-menu > .active > a:focus,
.request-quote-form .dropdown-menu > .active > a:hover {
  background-color: #447eb9;
}
.request-quote-form .open > .dropdown-toggle.btn-default {
  border-color: rgba(51, 51, 51, 0.3);
}
.request-quote-form .cta-button {
  margin-top: 5px;
  margin-bottom: 10px;
  width: 100%;
  height: 50px;
  font-size: 1.2em;
}
.request-quote-form .btn-cta {
  margin-top: 5px;
  margin-bottom: 10px;
  width: 100%;
  height: 50px;
  color: #fff;
  font-size: 1.2em;
  font-weight: 700;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.4);
  background: #ec971f linear-gradient(to bottom, rgba(255, 255, 255, 0.1) 0, rgba(255, 255, 255, 0) 100%);
  box-shadow: 0 -1px #c77c11 inset, 0 1px #f0ad4e inset;
  border: 1px solid #c77c11;
  border-top: 1px solid #eea237;
  text-transform: uppercase;
}
.request-quote-form .btn-cta:hover {
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0.1) 100%);
}
.request-quote-form .btn-cta:active {
  position: relative;
  top: 1px;
}
#header-feature-new {
  background: url('../img/header/nobg/background.jpg') no-repeat scroll center bottom / 100% auto #405570;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 0px 0px, rgba(0, 0, 0, 0.1) 0px 1px 4px;
}
@media (min-width: 710px) {
  #header-feature-new .floatLeft {
    float: left;
    width: 50%;
    position: relative;
    left: 10px;
    pointer-events: none;
  }
}
@media (min-width: 710px) {
  #header-feature-new .floatRight {
    float: right;
    width: 50%;
  }
}
#header-feature-new h1 {
  margin-top: 50px;
  margin-bottom: 10px;
  font-size: 3.5em;
  font-weight: 900;
  color: #333333;
  text-align: center;
  text-transform: uppercase;
}
#header-feature-new h1 + span {
  display: block;
  color: #333333;
  font-size: 1.6em;
  text-align: center;
  line-height: 1.2em;
}
#header-feature-new h1 + span span.bold {
  font-weight: bold;
}
#header-feature-new h1 + span a {
  color: #fff;
  text-decoration: underline;
}
#header-feature-new #header-feature-cta-wrap {
  padding-top: 50px;
  padding-bottom: 10px;
}
#header-feature-new #header-feature-car {
  position: relative;
  text-align: center;
}
#header-feature-new #header-feature-car img {
  margin-top: 50px;
  width: 100%;
  max-width: 100%;
}
@media (max-width: 709px) {
  #header-feature-new #header-feature-car img {
    max-width: 400px;
  }
}
#header-feature-new #header-feature-cta-box {
  background: rgba(255, 255, 255, 0.4);
  border-radius: 4px;
  padding: 5px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 3px;
}
#header-feature-new #header-feature-cta-box h4 {
  margin-top: 5px;
  margin-bottom: 10px;
  text-align: center;
}
#header-feature-new #header-feature-cta-box .selectpicker {
  width: 100%;
}
#header-feature-new #header-feature-cta-box button.selectpicker {
  font-weight: bold;
}
#header-feature-new #header-feature-cta-box .form-group {
  margin-bottom: 5px;
}
#header-feature-new #header-feature-cta-box .form-group-btns {
  text-align: center;
  margin-bottom: 5px;
}
#header-feature-new #header-feature-cta-box .btn-cta {
  width: 100%;
  height: 50px;
  color: #fff;
  font-weight: 600;
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.5);
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
  box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 5px;
  text-transform: uppercase;
}
#header-feature-new #header-feature-cta-box .btn-cta:hover {
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0.1) 100%);
}
#header-feature-new #header-feature-cta-box .btn-cta:active {
  position: relative;
  top: 1px;
}
#header-feature-new #header-feature-cta-box .btn-get-quote {
  background-color: #EC971F;
}
#header-feature-new #header-feature-cta-box .btn-learn-more {
  background-color: #447eb9;
}
@media (max-width: 991px) {
  .container {
    width: auto;
  }
}
#header {
  position: relative;
}
#header-top {
  background: #447eb9;
  width: 100%;
  height: 40px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  color: #fff;
  line-height: 40px;
}
#header-top a {
  color: #fff;
  text-decoration: underline;
}
#header-main-container {
  min-height: 75px;
  background: #fff;
}
#header-main {
  z-index: 1001;
  position: relative;
  width: 100%;
  min-height: 75px;
  background: rgba(255, 255, 255, 0.94);
  background-image: linear-gradient(to bottom, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 0px 0px, rgba(0, 0, 0, 0.1) 0px 1px 4px;
  min-height: 90px;
}
#header-main.fixed {
  position: fixed !important;
  top: 0;
  left: 0;
}
#header-main .logo {
  float: left;
  /*a {
            color: @dark-blue; //@color-base;//#002d57;
            text-transform: uppercase;
            font-size: 2.5em;
            font-weight: 900;
            //letter-spacing: 0.75px;
            text-decoration: none;
            line-height: 75px;
            & span {
                //color: @color-base;
            }
        }*/
}
#header-main .logo-new {
  display: block;
  padding-top: 18px;
  text-align: center;
}
#header-main .logo-new span {
  display: block;
}
#header-main .logo-new span:nth-of-type(1) {
  margin-bottom: 10px;
  font-size: 2.2em;
  font-weight: 900;
  color: #333333;
  text-transform: uppercase;
}
#header-main .logo-new span:nth-of-type(2) {
  font-size: 1.3em;
  font-weight: bold;
  color: #447eb9;
}
#header-main nav#header-navigation {
  position: relative;
  float: right;
  margin-top: 25px;
  text-transform: uppercase;
  line-height: 40px;
  font-size: 13px;
  font-weight: bold;
}
#header-main nav#header-navigation ul {
  display: block;
}
#header-main nav#header-navigation li {
  float: left;
}
#header-main nav#header-navigation a {
  display: block;
  margin-left: 5px;
  padding: 0 24px;
  border-radius: 4px;
  color: #364250;
  text-decoration: none;
}
#header-main nav#header-navigation a:hover {
  color: #447eb9;
}
#header-main nav#header-navigation a.active {
  color: #447eb9;
}
#header-main nav#header-navigation li.active a {
  background: #447eb9;
  color: #fff;
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.75);
}
#header-main nav#header-navigation li.active a:hover {
  box-shadow: inset rgba(0, 0, 0, 0.2) 0px 0px 0px 40px;
}
#header-main .number-new {
  font-size: 1.5em;
  font-weight: bold;
  color: #447eb9;
  line-height: 75px;
}
#header-main .number-new i {
  color: #333333;
}
#header-main .number-new {
  margin-top: 25px;
}
#header-main .number-new button {
  float: left;
  width: 150px;
  font-weight: bold;
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.75);
}
#header-main .quote-btn-new {
  margin-top: 25px;
}
#header-main .quote-btn-new button {
  float: right;
  width: 150px;
  font-weight: bold;
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.75);
}
.toggleNavigation {
  display: none;
  position: absolute;
  top: 17px;
  right: 5px;
  font-size: 20px;
  width: 40px;
  height: 40px;
  background: #d9e5f2;
  border-radius: 3px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
}
.header-feature-text {
  text-align: center;
  text-shadow: 2px 1px 3px rgba(0, 0, 0, 0.7);
  /*&:after {
        display: block;
        width: 40px;
        margin: 20px auto 20px;
        //margin: 30px auto 30px;
        border-bottom: 2px solid #fff;
        content: "";
    }*/
}
.header-feature-text h1 {
  margin-bottom: 10px;
  color: #fff;
  font-size: 3.2em;
  font-weight: 900;
  letter-spacing: 1px;
  line-height: 1.2em;
  text-transform: uppercase;
}
.header-feature-text h1 + span {
  display: inline-block;
  color: #fff;
  font-size: 1.6em;
  font-weight: 400;
}
.header-feature-text h1 + span span {
  font-weight: 900;
}
.header-feature-text h1 + span a {
  color: #fff;
  text-decoration: underline;
}
#header-cta {
  background: #447eb9;
  width: 100%;
  min-height: 75px;
  line-height: 75px;
  border-top: 2px solid #fff;
}
#header-cta form {
  text-align: center;
}
#header-cta .btn-cta {
  width: 100px;
}
#content {
  background: #fff;
}
#footer {
  width: 100%;
  padding-bottom: 20px;
  background: #447eb9;
  border-top: 1px solid #1a4268;
  line-height: 58px;
  color: #fff;
}
#footer a,
#footer a:hover {
  color: #fff;
}
.nav > li > a {
  padding-top: 20px;
  padding-bottom: 20px;
}
section:first-of-type {
  padding-top: 30px;
}
section:last-of-type {
  margin-bottom: 30px;
}
section:not(:last-of-type) {
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 3px dotted #d8e0e9;
}
section h3 {
  margin-bottom: 30px;
  text-align: center;
  font-weight: bold;
  /*&:after {
            display: block;
            width: 80px;
            margin: 15px auto 0;
            border-bottom: 3px dotted #d8e0e9;
            content: "";
        }*/
}
section p {
  margin-bottom: 0;
}
section.section-lead {
  text-align: center;
  text-transform: capitalize;
}
section.section-benefits {
  text-align: center;
}
section.section-benefits .benefits-item i {
  display: inline-block;
  position: relative;
  width: 100px;
  height: 100px;
  border: 2px solid #447eb9;
  border-radius: 50%;
  background: #447eb9;
  color: #fff;
  font-size: 40px;
  line-height: 100px;
  text-align: center;
  vertical-align: middle;
}
section.section-benefits .benefits-item h4,
section.section-benefits .benefits-item h5 {
  margin: 25px 0 10px;
  color: #364250;
  font-weight: bold;
}
section.section-how-it-works .how-it-works-steps ul li {
  text-align: left;
}
section.section-how-it-works .how-it-works-steps ul li:not(:last-of-type):after {
  display: block;
  width: 80px;
  margin: 20px auto;
  border-bottom: 3px dotted #d8e0e9;
  content: "";
}
section.section-how-it-works .how-it-works-steps ul li .steps-icon {
  float: left;
  padding-bottom: 10px;
  margin: 10px 18px 0 0;
  color: #447eb9;
  text-align: center;
  font-weight: bold;
}
section.section-how-it-works .how-it-works-steps ul li .steps-icon i {
  display: block;
  margin-bottom: 8px;
  font-size: 2em;
}
section.section-how-it-works .how-it-works-steps ul li h4,
section.section-how-it-works .how-it-works-steps ul li h5 {
  color: #364250;
  margin-bottom: 10px;
  text-transform: capitalize;
}
section.section-how-it-works .how-it-works-testimonials {
  /*.testimonial {
                text-align: left;
                .tText {
                    background: @color-base;
                    border-radius: 3px;
                    padding: 20px;
                    margin-bottom: 30px;
                    position: relative;
                    color: #fff;
                    text-align: left;
                }

                .tText:after {
                    content: " ";
                    position: absolute;
                    width: 0;
                    height: 0;
                    border: 14px solid transparent;
                    border-top-color: @color-base;
                    bottom: -28px;
                    left: 20px;
                }

                section:nth-of-type(odd) .testimonial .tText {
                    background: @color-base;
                }

                section:nth-of-type(odd) .testimonial .tText:after {
                    border-top-color: @color-base;
                }

                .photo {
                    width: 48px;
                    height: 48px;
                    float: left;
                    margin-left: -58px;
                    border-radius: 50%;
                    background-size: cover;
                    background-color: #222;
                    box-shadow: inset rgba(0, 0, 0, 0.2) 0px 0px 0px 1px;
                }

                .tAuthor {
                    padding-left: 68px;
                    line-height: 24px;
                }

                .name {
                    display: block;
                }

                .car {
                    display: block;
                    font-weight: 300;
                }
            }*/
}
section.section-faq {
  text-align: left;
}
section.section-faq li {
  display: block;
}
section.section-faq li:not(:last-of-type):after {
  display: block;
  width: 80px;
  margin: 20px auto;
  border-bottom: 3px dotted #d8e0e9;
  content: "";
}
section.section-faq li strong:before,
section.section-faq li h5:before {
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 5px;
  color: #447eb9;
  content: "Q.";
}
section.section-about .about-description {
  text-align: left;
}
section.section-about .about-description li {
  /*&:last-of-type {
                    margin-bottom: 10px;
                }
                &:not(:last-of-type):after {
                    display: block;
                    width: 80px;
                    margin: 20px auto;
                    border-bottom: 3px dotted #d8e0e9;
                    content: "";
                }*/
}
section.section-about .about-description li:not(:last-of-type) {
  margin-bottom: 30px;
}
section.section-about .about-description li h5 {
  margin-bottom: 5px;
  color: #333333;
}
section.section-about .about-contact h5 {
  margin-bottom: 5px;
}
section.section-about .about-contact .row {
  margin-left: -5px;
  margin-right: -5px;
}
section.section-about .about-contact div[class*='col'] {
  padding: 0 5px;
}
section.section-about .about-contact .call-us-box {
  margin-bottom: 20px;
}
section.section-about .about-contact button {
  height: 50px;
  font-weight: bold;
}
section.section-about .about-contact label {
  margin-bottom: 6px;
  line-height: 1;
}
section.section-about .about-contact .input-group-addon {
  padding-right: 0;
  border: 1px solid rgba(51, 51, 51, 0.3);
  border-right: 0;
}
section.section-about .about-contact .form-control,
section.section-about .about-contact .selectpicker {
  border: 1px solid rgba(51, 51, 51, 0.3);
  border-left: 0;
}
section.section-about .about-contact .form-control[type=text],
section.section-about .about-contact .form-control[type=email],
section.section-about .about-contact .form-control[type=tel] {
  height: 35px;
}
section.section-about .about-contact .input-group-addon {
  background: #fff;
  color: #447eb9;
}
section.section-about .about-contact .input-group-addon i {
  font-size: 1.2em;
}
section.section-final h3:after {
  content: none;
}
.m--how-it-works .step {
  text-align: left;
}
.m--how-it-works .step .icon {
  float: left;
  margin: 45px 18px 20px 0;
  color: #447eb9;
  text-align: center;
  font-weight: bold;
}
.m--how-it-works .step .icon i {
  display: block;
  font-size: 2em;
}
.m--how-it-works .step h5 {
  margin-bottom: 10px;
  text-transform: capitalize;
}
.m--how-it-works .step h5 i {
  display: inline-block;
  margin-right: 10px;
  color: #447eb9;
  font-size: 1.5em;
}
.m--how-it-works .step h5 span:nth-of-type(2) {
  color: #333333;
}
.m--how-it-works .step-sep {
  margin-bottom: 30px;
  /*&:after {
            display: block;
            width: 80px;
            margin: 20px auto;
            border-bottom: 3px dotted #d8e0e9;
            content: "";
        }*/
}
.m-testimonials .slick-slider {
  user-select: text;
}
.m-testimonials .testimonials .testimonial {
  margin-bottom: 10px;
}
@media (min-width: 768px) {
  .m-testimonials .testimonials .testimonial {
    margin-bottom: 0;
  }
}
.m-testimonials .testimonials .testimonial .photo {
  height: 80px;
}
.m-testimonials .testimonials .testimonial .photo img {
  position: absolute;
  top: 0;
  left: 150px;
  display: block;
  width: 90px;
  height: 90px;
  margin: 0 auto;
  z-index: 700;
}
.m-testimonials .testimonials .testimonial .name {
  font-weight: bold;
}
.m-testimonials .testimonials .testimonial .content {
  background: #447eb9;
  border-radius: 3px;
  padding: 20px;
  margin-bottom: 20px;
  position: relative;
  color: #fff;
  text-align: left;
  height: auto;
}
@media (min-width: 768px) {
  .m-testimonials .testimonials .testimonial .content {
    height: 157px;
  }
}
.m-testimonials .testimonials .testimonial .content:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  border: 14px solid transparent;
  border-top-color: #447eb9;
  bottom: -28px;
  left: 20px;
}
.m-testimonials .buttons {
  display: none;
  text-align: center;
}
.m-testimonials .buttons button {
  padding: 5px;
  width: 30px;
  height: 30px;
  border: none;
}
@media (min-width: 768px) {
  .m-testimonials .buttons {
    display: block;
  }
}
.quoteWrap h2 {
  color: #fff;
  background: rgba(0, 0, 0, 0.3);
  background: rgba(255, 255, 255, 0.8);
  padding: 20px;
  margin: -11px -11px 10px -11px;
  border-radius: 3px 3px 0px 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: rgba(255, 255, 255, 0.2) 0px 1px 0px 0px;
}
.quoteWrap {
  float: right;
  width: 50%;
  min-width: 520px;
  padding: 10px;
  background: rgba(0, 0, 0, 0.5);
  background: rgba(255, 255, 255, 0.8);
  /* background-image: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.3) 100%);
    box-shadow: inset rgba(0,0,0,0.3) 0px 0px 0px 1px, rgba(0,0,0,0.3) 0px 1px 10px;*/
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 3px;
  text-align: center;
}
.quoteBox {
  position: relative;
}
.quoteBox h2 {
  color: #222;
}
.quoteBox .instructions {
  color: #fff;
  text-shadow: rgba(0, 0, 0, 0.4) 0px 1px 2px, rgba(0, 0, 0, 0.4) 0px 1px 10px;
  font-size: 1.5em;
  font-weight: 500;
  padding: 0 15px;
}
.quoteForm {
  padding-top: 5px;
}
.quoteForm div[class*='col-'] {
  padding: 0 5px 10px 5px;
}
.selectCar {
  min-height: 56px;
}
.selectCar .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
  width: 100%;
}
.selectCar .btn-default {
  color: #222222;
  background: rgba(255, 255, 255, 0.9);
  background-image: linear-gradient(to bottom, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
  border-color: #ffffff;
  /* padding: 10px 16px; */
  /* box-shadow: rgba(0,0,0,0.2) 0px 1px 5px; */
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
  padding-top: 12px;
  padding-bottom: 12px;
}
.selectCar .btn-default:before {
  content: " ";
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 33px;
  background: #fff;
}
.selectCar .btn-default:active,
.selectCar .open > .dropdown-toggle.btn-default {
  background: rgba(255, 255, 255, 0.9);
  border-color: #ffffff;
  color: #222222;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
}
.selectCar .dropdown-menu {
  background: rgba(255, 255, 255, 0.9);
  border-color: #ffffff;
}
.selectCar .dropdown-menu > li > a {
  color: #222222;
}
.selectCar .dropdown-menu > li > a:hover {
  color: #222222;
  background: rgba(0, 0, 0, 0.05);
}
.selectCar .dropdown-menu > li > a:focus {
  outline: none;
  background: rgba(255, 255, 255, 0.05);
}
.quoteForm .form-control {
  height: auto;
}
.quoteForm .textInput {
  width: 100%;
  height: 42px;
  padding-left: 50px;
  color: #222222;
  border: 1px solid #fff;
  background: rgba(255, 255, 255, 0.9);
  background-image: linear-gradient(to bottom, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
  border-radius: 4px;
}
.quoteForm .textInput:focus {
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
}
.quoteForm .textInputIcon {
  position: relative;
  display: block;
}
.quoteForm .textInputIcon:before {
  content: attr(data-icon);
  font-family: 'FontAwesome';
  font-style: normal;
  font-weight: normal;
  line-height: 40px;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  transform: translate(0, 0);
  text-align: center;
  position: absolute;
  top: 1px;
  left: 1px;
  bottom: 1px;
  width: 40px;
  background: rgba(255, 255, 255, 0.6);
  box-shadow: inset rgba(123, 137, 152, 0.2) -1px 0px 0px 0px;
  color: #7b8998;
  pointer-events: none;
}
.quoteForm .row {
  margin: 0;
}
.quoteSubmit {
  border-radius: 3px;
  background: #e8a52d;
  background: linear-gradient(to bottom, #e9a52c 0%, #e49125 100%);
  /*
      border: 1px solid rgba(255,255,255,0.06);
      border-top-color: rgba(255,255,255,0.5);
    */
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.04) 0px 1px 2px, inset rgba(255, 255, 255, 0.1) 0px 1px 0px;
  color: #fff;
  text-shadow: rgba(0, 0, 0, 0.2) 0px -1px 0px;
  width: 100%;
  width: calc(100% - 10px);
  margin: 0 auto;
  display: block;
  line-height: 62px;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
}
/* Responsive header */
@media screen and (max-width: 900px) {
  .toggleNavigation {
    display: block;
  }
  #header-top {
    height: auto;
    text-align: center;
  }
  #header-top .pull-left,
  #header-top .pull-right {
    float: none !important;
    display: block;
  }
  #header-main {
    position: relative;
  }
  #header-main.fixed {
    position: relative !important;
  }
  #header-main .logo {
    float: none;
    text-align: center;
    padding-right: 45px;
  }
  #header-main .logo a {
    display: inline-block;
    font-size: 20px;
  }
  #header-main nav#header-navigation {
    float: none;
    margin-top: 0;
    text-align: center;
    padding-bottom: 10px;
    display: none;
  }
  #header-main nav#header-navigation.showNavigation {
    display: block;
  }
  #header-main nav#header-navigation ul {
    display: block;
  }
  #header-main nav#header-navigation li {
    float: none;
    display: block;
  }
  #header-main nav#header-navigation a {
    margin-left: 0;
  }
  /*#header-feature {
        height: auto;
    }*/
}
@media (max-width: 991px) {
  .benefits-item {
    padding-bottom: 30px;
  }
}
@media (max-width: 767px) {
  #section-about,
  .contact-form,
  .services-testimonials {
    padding-top: 50px;
  }
  #header-feature #header-feature-dark {
    padding: 40px 0;
  }
}
